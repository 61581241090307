import React from "react";
import { SzIcon, SzSideMenu } from "@suezenv/react-theme-components";
import { NavLink } from "react-router-dom";

interface IIcon {
    name: string;
    variant?: "line" | "bold";
    className?: string;
}
export interface MenuItemProps {
    icon: IIcon;
    title: string;
    route: string;
    isActive: boolean;
    onMenuItemClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, title, route, isActive, onMenuItemClick }) => {
    return (
        <NavLink to={route} activeClassName="menu-item-active">
            <SzSideMenu.Item active={isActive} onClick={onMenuItemClick}>
                <SzIcon icon={icon.name} variant={icon.variant ? icon.variant : "line"} className={icon.className} />
                <span className={"sz-aside__item--title"}>{title}</span>
            </SzSideMenu.Item>
        </NavLink>
    );
};

export default MenuItem;
