import { combineReducers, configureStore } from "@reduxjs/toolkit";
import errorReducer from "./errors/errors.reducer";
import layoutReducer from "./layout/layout.reducer";
import userReducer from "./user/user.reducer";

const rootReducer = combineReducers({
    userReducer,
    errorReducer,
    layoutReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
    reducer: rootReducer,
});

export default store;
