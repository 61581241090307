import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getContract } from "../services/user.util";
import { RootState } from "../store";
import { ContractType } from "@suezenv/signal-widget/dist/signal-app/type/security.type";

export const useContract = (): ContractType | undefined => {
    const userState = useSelector((state: RootState) => state.userReducer);
    return useMemo(() => {
        return getContract(userState);
    }, [userState]);
};
