import { config } from "../config";
const MEDUIM_WIDTH = 768;
// update freshDesk bytton display based on screen size
const freshDeskButtonDisplay = (screenWidth: number) => {
    const freshworksContainerIframe = document.querySelector(
        "#freshworks-container #launcher-frame",
    ) as HTMLIFrameElement;
    if (freshworksContainerIframe) {
        const text: HTMLElement | null | undefined =
            freshworksContainerIframe.contentWindow?.document.querySelector(".launcher-text");
        const svgElement: SVGSVGElement | null | undefined =
            freshworksContainerIframe.contentWindow?.document.querySelector("svg");
        if (text && svgElement) {
            if (screenWidth < MEDUIM_WIDTH) {
                text.style.display = "none";
                svgElement.style.width = "25px";
                svgElement.style.height = "25px";
            } else {
                text.style.display = "flex";
                svgElement.style.width = "16px";
                svgElement.style.height = "16px";
            }
        }
    }
};
window.onresize = () => {
    freshDeskButtonDisplay(window.innerWidth);
};
export class FreshDesk {
    public static setup() {
        // // freshdesk integration
        if (config.freshdeskId) {
            window.fwSettings = {
                widget_id: config.freshdeskId,
            };
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://widget.freshworks.com/widgets/" + config.freshdeskId + ".js";
            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }
}
