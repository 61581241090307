import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SignalType = "map" | "list";

interface ILayout {
    menuIsOpen: boolean;
    signalType: SignalType;
}

const initialState: ILayout = {
    menuIsOpen: false,
    signalType: "map",
};

const layoutSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        toggle: (state) => {
            state.menuIsOpen = !state.menuIsOpen;

            return state;
        },
        closeMenu: (state) => {
            state.menuIsOpen = false;

            return state;
        },
        changeSignalType: (state, action: PayloadAction<SignalType>) => {
            state.signalType = action.payload;
            return state;
        },
    },
});

export const { toggle, closeMenu, changeSignalType } = layoutSlice.actions;

export default layoutSlice.reducer;
