import React, { useMemo } from "react";
import Menu from "./menu/menu";
import Header from "./header/header";
import Content, { RouteItem } from "./content/content";
import { additionalRoutes, getMenuItems } from "./menu/menu-item-list";
import { useTranslationWithPrefix } from "../../hooks/translation";

const Layout: React.FC = () => {
    const trans = useTranslationWithPrefix("translation", "menu");
    const routes = useMemo<RouteItem[]>(
        () => [...getMenuItems(trans), ...additionalRoutes],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <div className="main-layout-wrapper h-100">
            <Header />
            <Menu />
            <Content routes={routes} />
        </div>
    );
};

export default Layout;
