import { APPLICATION_NAME } from "../constants/application";


declare global {
    interface Window {
        REACT_APP_API_HOST: string;
        REACT_APP_FRESHDESK: number;
        REACT_APP_GEOSERVER_AUTHKEY: string;
        REACT_APP_GEOSERVER_PROPERTY_NAME: string;
        REACT_APP_GEOSERVER_URL: string;
        REACT_APP_ZEUS_CONTRACT: string;
        REACT_APP_APPLICATION_NAME: string;
        fwSettings: {
            widget_id: number;
        };
    }
}

// use process.env in dev instead window
export const config = {
    apiUrl: window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST,
    freshdeskId: Number(window.REACT_APP_FRESHDESK || process.env.REACT_APP_FRESHDESK),
    geoserverAuthkey: window.REACT_APP_GEOSERVER_AUTHKEY || process.env.REACT_APP_GEOSERVER_AUTHKEY,
    geoserverPropertyName: window.REACT_APP_GEOSERVER_PROPERTY_NAME || process.env.REACT_APP_GEOSERVER_PROPERTY_NAME,
    geoserverUrl: window.REACT_APP_GEOSERVER_URL || process.env.REACT_APP_GEOSERVER_URL,
    zeusContract: window.REACT_APP_ZEUS_CONTRACT || process.env.REACT_APP_ZEUS_CONTRACT,
    applicationName: window.REACT_APP_APPLICATION_NAME || process.env.REACT_APP_APPLICATION_NAME || APPLICATION_NAME,
};
