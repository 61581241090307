import { StringMap, TOptions } from "i18next";
import { useTranslation } from "react-i18next";

export const useTranslationWithPrefix = (namespace: string, prefix: string) => {
    const { t } = useTranslation(namespace);

    return (key: string, options?: TOptions<StringMap> | string) => {
        return t(`${prefix}.${key}`, options);
    };
};
