import { TFunction } from "i18next";
import SignalCreateBridge from "../../../pages/signal-create-bridge";
import { CREATE_SIGNAL_ROUTE, HOME_ROUTE, SIGNAL_DETAIL_ROUTE } from "../../../constants/routes";
import SignalViewBridge from "../../../pages/signal-view-bridge";
import { MenuItemProps } from "./menu-item";
import SignalDetailBridge from "../../../pages/signal-detail-bridge";
import { RouteItem } from "../content/content";

export const getMenuItems = (trans: TFunction): MenuItemProps[] => {
    const menuItems = [
        {
            icon: {
                name: "task-finger-show",
            },
            title: trans("list_signalement"),
            page: SignalViewBridge,
            route: HOME_ROUTE,
            isActive: false
        },
        {
            icon: {
                name: "add-circle",
                variant: "bold" as "line" | "bold",
                className: "add-signal-icon",
            },
            title: trans("create_signalement"),
            page: SignalCreateBridge,
            route: CREATE_SIGNAL_ROUTE,
            isActive: false
        },
    ];

    return menuItems;
};

export const additionalRoutes: RouteItem[] = [
    {
        route: SIGNAL_DETAIL_ROUTE,
        page: SignalDetailBridge,
    },
];
