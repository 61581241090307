import React, { useMemo } from "react";
import { SzSideMenu } from "@suezenv/react-theme-components";
import MenuItem, { MenuItemProps } from "./menu-item";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { getMenuItems } from "./menu-item-list";
import "./menu.scss";
import { useLocation } from "react-router";
import { closeMenu } from "../../../store/layout/layout.reducer";

const Menu: React.FC = () => {
    const layoutState = useSelector((state: RootState) => state.layoutReducer);

    const location = useLocation();

    const trans = useTranslationWithPrefix("translation", "menu");

    const menuItems: MenuItemProps[] = useMemo(() => {
        return getMenuItems(trans);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const dispatch = useDispatch();
    const closeMenuOnMobile = () => {
        if (window.innerWidth < 768) {
            dispatch(closeMenu());
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 400);
        }
    };
    return (
        <div className="content-wrapper">
            <div className="menu-wrapper">
                <SzSideMenu className="position-relative float-left" isOpen={layoutState.menuIsOpen}>
                    {menuItems.map((menu, index) => (
                        <MenuItem key={index}  {...menu} isActive={location.pathname === menu.route} onMenuItemClick={closeMenuOnMobile}/>
                    ))}

                </SzSideMenu>
            </div>
        </div>
    );
};

export default Menu;
