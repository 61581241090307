import React from "react";
import { useHistory } from "react-router";
import { config } from "../config";
import { useContract } from "../hooks/contract.hooks";
import { CREATE_SIGNAL_ROUTE } from "../constants/routes";
import { SignalView } from "@suezenv/signal-widget";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { GEOSHAPE_92 } from "../constants/application";

const SignalViewBridge: React.FC = () => {
    const history = useHistory();
    const contract = useContract();
    const layoutState = useSelector((state: RootState) => state.layoutReducer);
    const userState = useSelector((state: RootState) => state.userReducer);
    const handleAddClick = () => {
        history.push(CREATE_SIGNAL_ROUTE);
    };

    const displaySignal = (signalId: string) => {
        history.push(`signal/${signalId}/detail`);
    };

    return (
        <SignalView
            geoServer={{
                url: config.geoserverUrl as string,
                workspace: `UUID-${config.zeusContract}`,
                layer: `UUID-${contract?.id}`,
                authkey: config.geoserverAuthkey as string,
            }}
            addSignal={handleAddClick}
            goSignalDetail={displaySignal}
            signalType={layoutState.signalType}
            user={userState}
            geoShape={GEOSHAPE_92}
        />
    );
};

export default SignalViewBridge;
