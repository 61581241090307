import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SzButton, SzModal } from "@suezenv/react-theme-components";
import { Authenticate } from "../../services/Authenticate";

const ErrorAuthenticationMessage = (props: any) => {
    const [show, isShow] = useState(true);
    const { t } = useTranslation();
    useEffect(() => {
        isShow(props.show);
    }, [props.show]);

    const handleClose = () => {
        Authenticate.logout();
    };

    const handleClick = () => {
        Authenticate.logout();
    };

    return (
        <SzModal title={t("error.session_expired")} show={show} size="lg" handleClose={handleClose}>
            <p className="text-center">{t("error.reconnect_messsage")}</p>
            <div className="mt-1 m-auto">
                <div className="row justify-content-center">
                    <SzButton className="col-9 col-md-3 m-0" onClick={handleClick}>
                        {t("error.reconnect_button")}
                    </SzButton>
                </div>
            </div>
        </SzModal>
    );
};

export default ErrorAuthenticationMessage;
