import React from "react";
import { config } from "../config";
import { useContract } from "../hooks/contract.hooks";
import { SignalCreate } from "@suezenv/signal-widget";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { HOME_ROUTE } from "../constants/routes";
import { useHistory } from "react-router-dom";
import { GEOSHAPE_92 } from "../constants/application";

const SignalCreateBridge: React.FC = () => {
    const contract = useContract();
    const history = useHistory();
    const userState = useSelector((state: RootState) => state.userReducer);

    return (
        <SignalCreate
            geoServer={{
                url: config.geoserverUrl as string,
                workspace: `UUID-${config.zeusContract}`,
                layer: `UUID-${contract?.id}`,
                authkey: config.geoserverAuthkey as string,
            }}
            user={userState}
            onSignalCreated={() => {
                history.push(HOME_ROUTE);
            }}
            geoShape={GEOSHAPE_92}
        />
    );
};

export default SignalCreateBridge;
