import React from "react";
import { SzDropDown, SzHeader, SzIcon } from "@suezenv/react-theme-components";
import { useDispatch, useSelector } from "react-redux";
import { Authenticate } from "../../../services/Authenticate";
import { toggle } from "../../../store/layout/layout.reducer";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { RootState } from "../../../store";
import "./header.scss";
import SignalViewSelector from "./signal-view-selector";
import { config } from "../../../config";

const Header: React.FC = (): JSX.Element => {
    const dispatch = useDispatch();
    const trans = useTranslationWithPrefix("translation", "header");
    const userState = useSelector((state: RootState) => state.userReducer);

    const toggleMenu = () => {
        dispatch(toggle());
    };

    return (
        <SzHeader className="d-flex  header-wrapper align-items-center">
          
                <div className="logo-wrapper align-items-center ">
                    <div className="menu-icon text-secondary">
                        <SzIcon variant="line" icon="navigation-menu" onClick={toggleMenu} />
                    </div>
                    
                </div>
      
            
            
            <div className="ml-1">
                    <div ><h1>{config.applicationName}</h1></div>
            </div>

            <div className="text-secondary border-right ml-auto ">
                <div className="menu-selected d-flex align-items-center">
                    <SzDropDown
                        alignRight={true}
                        value={
                            <>
                                <span className={"menu-title d-none d-sm-inline-flex"}>
                                    {userState?.data?.firstName}
                                </span>{" "}
                                <SzIcon icon={"people-man-1"} variant="line" />
                            </>
                        }
                        icon="people-man-1"
                    >
                        <SzDropDown.Item onClick={Authenticate.logout}>
                            <a href="/#">{trans("logout")}</a>
                        </SzDropDown.Item>
                    </SzDropDown>
                </div>
            </div>
        </SzHeader>
    );
};

export default Header;
