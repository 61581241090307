import { Request } from "./Request";
import store from "../store";
import { setError } from "../store/errors/errors.reducer";
import { setLoading } from "../store/loading/loading.reducer";

export default new Request(
    () => {
        store.dispatch(
            setLoading({
                isLoading: true,
            }),
        );
    },
    () => {
        store.dispatch(
            setLoading({
                isLoading: false,
            }),
        );
    },
    (error) => {
        store.dispatch(
            setLoading({
                isLoading: false,
            }),
        );
        if (error.response) {
            const { status } = error.response;
            const isJwtError = 401 === status;

            if (isJwtError) {
                store.dispatch(setError({ jwtCheck: true, isError: true, status }));
            } else {
                store.dispatch(setError({ jwtCheck: false, isError: true, status }));
            }
        }
    },
);
