import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "@suezenv/signal-widget/dist/signal-app/type/user.type";

const initialState: UserType = {
    isLogged: false,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<Partial<UserType>>) => {
            state = { ...state, ...action.payload };

            return state;
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
