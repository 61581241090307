import { config } from "../config";
import { Token as TokenService } from "./Token";

export class Authenticate {
    public static intialize() {
        TokenService.createTokens();
        TokenService.checkJWTValidityFromAPI();
    }

    public static login() {
        return (window.location = `${config.apiUrl}/saml/login` as any);
    }

    public static logout() {
        localStorage.clear();
        window.location = `${config.apiUrl}/saml/logout` as any;
    }
}
