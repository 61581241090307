import { ContractType } from "@suezenv/signal-widget/dist/signal-app/type/security.type";
import { UserType } from "@suezenv/signal-widget/dist/signal-app/type/user.type";
import { SERVICES_LIST } from "../constants/services";

export const getContract = (user: UserType): ContractType | undefined => {
    if (user.data) {
        const signalServices = Object.keys(user.data.services).filter((serviceCode: string) =>
            SERVICES_LIST.includes(serviceCode),
        );
        if (signalServices && signalServices.length > 0) {
            return user.data?.services[signalServices[0]][0];
        }
    }
    return undefined;
};
