import React, {useState} from "react";
import { useHistory, useParams } from "react-router";
import { SignalDetail } from "@suezenv/signal-widget";
import { HOME_ROUTE } from "../constants/routes";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ISignalCustomParams } from "@suezenv/signal-widget/dist/signal-app/type/signal.type";

const SignalDetailBridge: React.FC = () => {
    const history = useHistory();
    const userState = useSelector((state: RootState) => state.userReducer);
    const { id } = useParams<{ id: string }>();

    const [customParams,] = useState<ISignalCustomParams>({
        isDisplayCreateRequestAndCloseButton: false,
    } as ISignalCustomParams );

    return <SignalDetail
        signalId={id}
        user={userState}
        onBackClicked={() => history.push(HOME_ROUTE)}
        customParams={customParams}
        customAction={() => {}}
    />;
};

export default SignalDetailBridge;
