import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Theme } from "@suezenv/react-theme-components";
import { Authenticate } from "../services/Authenticate";
import { RootState } from "../store";
import Layout from "./layout/layout";
import ErrorAuthenticationMessage from "./messages/ErrorAuthenticationMessage";
import { FreshDesk } from "../services/FreshDesk";
import "./App.scss";

function App() {
    const errorState = useSelector((state: RootState) => state.errorReducer);
    const userState = useSelector((state: RootState) => state.userReducer);

    useEffect(() => {
        Authenticate.intialize();
        FreshDesk.setup();
    }, []);

    return (
        <Theme.Provider brand="SEVE">
            <main className="main-content h-100">
                {errorState.isError && !errorState.jwtCheck && <div>{"erreur"}</div>}
                {errorState.isError && errorState.jwtCheck && <ErrorAuthenticationMessage show={true} />}
                {userState.isLogged && userState.data && <Layout />}
            </main>
        </Theme.Provider>
    );
}

export default App;
