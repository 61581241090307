import { Route, Switch } from "react-router-dom";
import React from "react";
import "./content.scss";
export interface RouteItem {
    route: string;
    page?: React.ComponentType;
    role?: string; // required role to have access to the page.
}

interface Props {
    routes: RouteItem[];
}

const Content: React.FC<Props> = ({ routes }) => {
    return (
        <main className="container-content">
            <Switch>
                {routes.map(({ route, page }) => (
                    <Route exact={true} key={route} path={route} component={page as any} />
                ))}
            </Switch>
        </main>
    );
};

export default Content;
