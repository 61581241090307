export const APPLICATION_NAME = "Signal";


// Center map around Hauts-de-Seine (92)
// https://france-geojson.gregoiredavid.fr/repo/departements/92-hauts-de-seine/departement-92-hauts-de-seine.geojson
export const GEOSHAPE_92: GeoJSON.Feature = {
  "type": "Feature",
  "geometry": {
    "type": "Polygon",
    "coordinates": [
      [
        [
          2.2909694895753,
          48.950965864655
        ],
        [
          2.3269728001661,
          48.945361349484
        ],
        [
          2.3355682105858,
          48.940679976956
        ],
        [
          2.3363529889891,
          48.932347933816
        ],
        [
          2.3198186224138,
          48.915937453257
        ],
        [
          2.3137528853936,
          48.914015216252
        ],
        [
          2.3198871747441,
          48.90045978209
        ],
        [
          2.2915068524977,
          48.8894718708
        ],
        [
          2.2774870298138,
          48.877968320853
        ],
        [
          2.2584671711142,
          48.880387263086
        ],
        [
          2.2317363597469,
          48.86906858161
        ],
        [
          2.2242191058804,
          48.853516917557
        ],
        [
          2.2506124417162,
          48.845554851211
        ],
        [
          2.2551442384175,
          48.834809549369
        ],
        [
          2.2727931901868,
          48.827920084226
        ],
        [
          2.2790519306533,
          48.832489952145
        ],
        [
          2.2921959226619,
          48.82714160912
        ],
        [
          2.3318980606376,
          48.817010929642
        ],
        [
          2.3186962648686,
          48.787997409568
        ],
        [
          2.3258147798433,
          48.781908297526
        ],
        [
          2.3102224901101,
          48.75695290327
        ],
        [
          2.3207207141635,
          48.74875804931
        ],
        [
          2.3132939902039,
          48.730280485815
        ],
        [
          2.3038400608379,
          48.72948996497
        ],
        [
          2.2983820323129,
          48.738637828397
        ],
        [
          2.2802904545364,
          48.733011078618
        ],
        [
          2.274821276941,
          48.740090859412
        ],
        [
          2.2853936451259,
          48.747508493238
        ],
        [
          2.275033240625,
          48.755288275593
        ],
        [
          2.2593929492592,
          48.761011671227
        ],
        [
          2.2476645497167,
          48.761102963541
        ],
        [
          2.2329743918365,
          48.766192980349
        ],
        [
          2.2265538842831,
          48.776101996393
        ],
        [
          2.223319655228,
          48.785943206962
        ],
        [
          2.2115088216259,
          48.788138691837
        ],
        [
          2.2026391702069,
          48.798400652391
        ],
        [
          2.1836952927536,
          48.797237542236
        ],
        [
          2.1766702709493,
          48.813808667803
        ],
        [
          2.1605570086469,
          48.813043649008
        ],
        [
          2.1515805126399,
          48.817021316976
        ],
        [
          2.1458760215967,
          48.836022751826
        ],
        [
          2.1532113486874,
          48.848852456588
        ],
        [
          2.1528705339192,
          48.873338063287
        ],
        [
          2.1677833601069,
          48.894417281801
        ],
        [
          2.1765183808683,
          48.900349435762
        ],
        [
          2.2005910092695,
          48.908679329899
        ],
        [
          2.231140371461,
          48.927732507513
        ],
        [
          2.2687285425666,
          48.946575058002
        ],
        [
          2.2909694895753,
          48.950965864655
        ]
      ]
    ]
  },
  "properties": {
    "code": "92",
    "nom": "Hauts-de-Seine"
  }
};